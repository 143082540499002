<template>
  <div>
    <ListTemplate>
      <template v-slot:title>
        <h1>Użytkownicy</h1>
      </template>
      <template v-slot:header>
        <div>
          <v-btn
            small
            color="primary"
            @click="openCreateModal"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:content>
        <UsersList
          :data="data"
          :loading="loading"
          :pagination="pagination"
          @fetchData="fetchData"
          @remove="setCandidateToRemove"
          @edit="setUserToEdit"
        />
      </template>
    </ListTemplate>
    <UserForm
      v-if="userId"
      :userId="userId"
      @close="closeForm"
      @refreshList="fetchData"
    />
    <ConfirmationDialog
      v-if="userToRemove"
      :value="!!userToRemove"
      :loading="userRemoveLoading"
      @confirm="deleteUser"
      @deny="userToRemove = null"
    />
  </div>
</template>

<script>
import {notify, notifyStandardError} from "@/services/notificationService";
import ListTemplate from "@/templates/ListTemplate";
import UsersList from "@/components/users/UsersList";
import UserForm from "@/components/users/UserForm";
import {fetchUsers, removeUser} from "@/services/usersService";
import ConfirmationDialog from "@/components/system/ConfirmationDialog";

export default {
  name: 'Users',
  components: {ConfirmationDialog, UserForm, UsersList, ListTemplate},
  data: () => ({
    data: [],
    loading: false,
    pagination: {
      perPage: 10,
      totalElements: 0,
      page: 1
    },
    userId: null,

    userToRemove: null,
    userRemoveLoading: false,
  }),
  methods: {
    setCandidateToRemove(id) {
      this.userToRemove = this.data.find(item => item.id === id)
    },
    setUserToEdit(id) {
      this.userId = id
    },
    closeForm() {
      this.userId = null
    },
    async fetchData() {
      try {
        this.loading = true
        const {data, totalElements} = await fetchUsers(this.pagination)
        this.data = data
        this.pagination.totalElements = totalElements
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    },
    async deleteUser() {
      try {
        this.userRemoveLoading = true
        await removeUser(this.userToRemove.id)
        this.userToRemove = null
        notify('success', 'Użytkownik został usunięty')
        await this.fetchData()
      } catch (e) {
        notifyStandardError()
      } finally {
        this.userRemoveLoading = false
      }
    },
    openCreateModal() {
      this.userId = 'create'
    }
  }
}
</script>

<style scoped>

</style>
