<template>
  <ModalTemplate :isOpen="!!userId">
    <template v-slot:title>
      <h3 class="headline">
        {{ isCreateMode ? 'Nowy użytkownik' : 'Edycja użytkownika' }}
      </h3>
    </template>
    <template v-slot:content>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col :cols="12" sm="6">
              <v-text-field
                v-model="form.firstName"
                label="Imię"
                :rules="rules.firstName"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col :cols="12" sm="6">
              <v-text-field
                v-model="form.lastName"
                label="Nazwisko"
                :rules="rules.lastName"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col :cols="12" sm="6">
              <v-text-field
                v-model="form.email"
                label="Email"
                :rules="rules.email"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Rola"
                :items="roles"
                v-model="form.roleId"
                item-text="name"
                item-value="id"
                :loading="rolesLoading"
                :rules="rules.roleId"
                clearable
              />
            </v-col>
            <v-col :cols="12" sm="6">
              <v-text-field
                v-model="form.redtrainId"
                label="RedTrain Id"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn color="primary" :loading="saveLoading" text @click="save">
        Zapisz
      </v-btn>
      <v-btn color="info" :disabled="saveLoading" text @click="close">
        Anuluj
      </v-btn>
    </template>
  </ModalTemplate>
</template>

<script>
import ModalTemplate from "@/templates/ModalTemplate";
import {email, required} from "@/utils/validationRules";
import {notify, notifyStandardError} from "@/services/notificationService";
import {clone} from "@/utils/dataTransformation";
import {fetchUser, fetchUserRoles, saveUser} from "@/services/usersService";

const emptyForm = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: null,
  redtrainId: null
}

export default {
  name: 'UserForm',
  components: {ModalTemplate},
  props: {
    userId: [String, Number]
  },
  data: () => ({
    fetchLoading: false,
    saveLoading: false,
    rolesLoading: false,
    roles: [],
    rules: {
      firstName: [required],
      lastName: [required],
      email: [required, email],
      roleId: [required]
    },
    form: clone(emptyForm)
  }),
  beforeMount() {
    if (!this.isCreateMode) {
      this.fetchUser()
    }
    this.fetchRoles()
  },
  computed: {
    isCreateMode() {
      return this.userId === 'create'
    }
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.saveLoading = true
          const userId = this.isCreateMode ? null : this.userId
          await saveUser(this.form, userId)
          this.emitRefresh()
          this.close()
          notify('success', `Użytkownik został ${this.isCreateMode ? 'stworzony' : 'zmodyfikowany'}`)
        } catch (e) {
          notifyStandardError()
        } finally {
          this.saveLoading = false
        }
      }
    },
    emitRefresh() {
      this.$emit('refreshList')
    },
    close() {
      // this.$refs.form.resetValidation()
      this.$emit('close')
    },
    async fetchUser() {
      try {
        this.fetchLoading = true
        this.form = await fetchUser(this.userId)
      } catch (e) {
        notify('error', 'Nie udało się pobrać dane użytkownika')
      } finally {
        this.fetchLoading = false
      }

    },
    async fetchRoles() {
      try {
        this.rolesLoading = true
        this.roles = await fetchUserRoles()
      } catch (e) {
        notify('error', 'Nie udało się pobrać role')
      } finally {
        this.rolesLoading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.row > * {
  padding: 0 6px;
}
</style>
